import React, { Component } from 'react';
import IconButton from '@mui/material/IconButton';
import Icon from 'components/ui-components-v2/Icon';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid2';
import TextField from 'components/ui-components-v2/TextField';
import Select from 'components/ui-components-v2/Select';
import Comparisons from '../models/comparisons';
import '../styles/main.scss';

/**
 * FieldMap class
 * Displays a row source items and input fields
 * This is used to map the value of the dynamic templates to the values of the new records in the FeedManager
 */
export default class MappingFields extends Component {
    static defaultProps = {
        value: []
    };

    /**
     * Change input
     * Renders the local state and sends the new value up
     */
    onChange = (event, index, key, inputRow = {}) => {
        const { onChangeValue, value } = this.props;
        const newValue = [...value];

        let inputValue = event.target.value;

        // Change the value to an array for these comparison types
        if (inputRow.comparison === 'in' || inputRow.comparison === 'nin') {
            inputValue = inputValue.split(',').map((v) => v.trim());
        }

        newValue[index][key] = inputValue;

        onChangeValue(newValue);
    };

    render() {
        const { value, addInputRow, removeInputRow, fieldLabel, valueLabel, valueOptions, hasComparison, canAdd } = this.props;
        let { fieldOptions } = this.props;

        const columnWidth = hasComparison ? 4 : 6;

        fieldOptions = [...[{ value: '', label: 'No value' }], ...fieldOptions];

        return (
            <div className="form-flow__field__field-map">
                <div className="form-flow__field__field-map__labels">
                    <div className="form-flow__field__field-map__labels__copy">
                        <Grid container spacing={2}>
                            <Grid size={columnWidth}>{fieldLabel}</Grid>
                            {hasComparison && <Grid size={columnWidth}>Condition</Grid>}
                            <Grid size={columnWidth}>{valueLabel}</Grid>
                        </Grid>
                    </div>
                    {canAdd && <div className="form-flow__field__field-map__labels__delete"></div>}
                </div>

                <div className="form-flow__field__field-map__inputs">
                    {value.map((inputRow, index) => {
                        return (
                            <div key={index} className="form-flow__field__field-map__inputs__wrapper">
                                <div className="form-flow__field__field-map__inputs__wrapper__grid">
                                    <Grid container spacing={2}>
                                        <Grid size={columnWidth}>
                                            {fieldOptions && (
                                                <FormControl variant="outlined" fullWidth>
                                                    <Select
                                                        native
                                                        fullWidth
                                                        value={inputRow.field ? inputRow.field : ''}
                                                        onChange={(e) => this.onChange(e, index, 'field')}>
                                                        {fieldOptions.map((item, i) => (
                                                            <option value={item.value} key={i}>
                                                                {item.label}
                                                            </option>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            )}
                                            {!fieldOptions && (
                                                <TextField
                                                    value={inputRow.field}
                                                    onChange={(e) => this.onChange(e, index, 'field')}
                                                    variant="outlined"
                                                    style={{ marginTop: 0, width: '100%' }}
                                                />
                                            )}
                                        </Grid>

                                        {hasComparison && (
                                            <Grid size={columnWidth}>
                                                <FormControl variant="outlined" fullWidth>
                                                    <Select
                                                        native
                                                        fullWidth
                                                        value={inputRow.comparison ? inputRow.comparison : ''}
                                                        onChange={(e) => this.onChange(e, index, 'comparison')}>
                                                        {Comparisons.map((item, i) => (
                                                            <option value={item.value} key={i}>
                                                                {item.label}
                                                            </option>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        )}

                                        <Grid size={columnWidth}>
                                            {valueOptions && (
                                                <FormControl variant="outlined" style={{ width: '100%' }}>
                                                    <Select
                                                        native
                                                        fullWidth
                                                        value={inputRow.value ? inputRow.value : ''}
                                                        onChange={(e) => this.onChange(e, index, 'value')}>
                                                        {valueOptions.map((item, i) => (
                                                            <option value={item.value} key={i}>
                                                                {item.label}
                                                            </option>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            )}
                                            {!valueOptions && (
                                                <TextField
                                                    value={inputRow.value ? inputRow.value : ''}
                                                    onChange={(e) => this.onChange(e, index, 'value', inputRow)}
                                                    placeholder={
                                                        inputRow.comparison === 'in' || inputRow.comparison === 'nin'
                                                            ? 'Seperate by comma (e.g. product,price,value)'
                                                            : null
                                                    }
                                                    variant="outlined"
                                                    style={{ marginTop: 0, width: '100%' }}
                                                />
                                            )}
                                        </Grid>
                                    </Grid>
                                </div>
                                {canAdd && (
                                    <div className="form-flow__field__field-map__inputs__wrapper__delete">
                                        <IconButton size="small" onClick={() => removeInputRow(index)}>
                                            <Icon>delete</Icon>
                                        </IconButton>
                                    </div>
                                )}
                            </div>
                        );
                    })}
                </div>

                <div className="form-flow__field__field-map__actions">
                    <IconButton size="small" onClick={() => addInputRow()} color="primary">
                        <Icon>add_circle</Icon>
                    </IconButton>
                </div>
            </div>
        );
    }
}
